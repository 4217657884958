<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#182444"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#ff6200" right top timeout="2000">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <!-- <v-layout wrap justify-start pt-2>
      <v-flex xs12 sm3 align-self-center pl-8>
        <span
          class="title1"
          :style="{
            'font-size':
              $vuetify.breakpoint.name == 'xs'
                ? '20px'
                : $vuetify.breakpoint.name == 'sm'
                ? '20px'
                : $vuetify.breakpoint.name == 'md'
                ? '25px'
                : $vuetify.breakpoint.name == 'lg'
                ? '25px'
                : '30px',
          }"
          >{{ roomData.roomsDisplayName }}</span
        >
      </v-flex>
    </v-layout> -->
    <v-layout wrap justify-center class="mainfont">
      <v-flex xs12 align-self-center>
        <v-card tile class="pa-4">
          <v-layout wrap>
            <v-flex xs12 v-if="roomData">
  <!-- Carousel for roomAdditionalImages -->
  <v-carousel
    v-if="roomData.roomAdditionalImages && roomData.roomAdditionalImages.length > 0"
    height="400px"
    cycle
    autoplay
    :interval="5000"
    hide-delimiters
    show-arrows
  >
    <v-carousel-item
      v-for="(image, index) in roomData.roomAdditionalImages"
      :key="index"
      :src="mediaUURL + image"
    >
      <template v-slot:placeholder>
        <ImageLoader />
      </template>
      
      <!-- Layout within each carousel item -->
      <v-layout justify-start fill-height>
        <v-flex xs3 sm2 align-self-end mb-4 ml-1 ml-sm-8>
          <!-- Card for roomImage (like coverImage in previous code) -->
          <v-card
            height="110px"
            width="150px"
            class="pa-1 "
          >
            <v-img
              height="100%"   v-if="roomData.roomImage"
              width="100%"
              :src="mediaUURL + roomData.roomImage"
              contain
            >
              <template v-slot:placeholder>
                <ImageLoader />
              </template>
            </v-img>
            <v-img
              height="100%"
              v-else
              src="./../../../assets/images/noimg.png"
              contain
            >
              <template v-slot:placeholder>
                <ImageLoader />
              </template>
            </v-img>
          </v-card>
        </v-flex>
        <v-flex xs7 sm8 align-self-end mb-4 pl-2>
          <!-- roomsDisplayName Display -->
          <span
            style="
              font-family: LexendFont;
              font-weight: 500;
              font-size: 22px;
              color: white;
            "
            v-if="roomData.roomsDisplayName"
            >{{ roomData.roomsDisplayName }}</span
          >
        </v-flex>
        <v-flex xs2 align-self-end text-right mb-4 pr-8>
          <v-btn
            style="border-radius: 2px"
            class="hidden-sm-and-down"
            color="grey"
            @click="$router.push('/editRoom?id=' + $route.query.id)"
          >
            <span
              style="
                font-family: LexendFont;
                font-weight: 600;
                font-size: 15px;
                color: white;
              "
              >Edit</span
            >
          </v-btn>
          <v-btn
            class="hidden-md-and-up"
            style="border-radius: 2px"
            text
            @click="$router.push('/editRoom?id=' + $route.query.id)"
          >
            <span
              style="
                font-family: LexendFont;
                font-weight: 600;
                font-size: 15px;
                color: white;
              "
              ><v-icon>mdi-pencil</v-icon></span
            >
          </v-btn>
        </v-flex>
      </v-layout>
    </v-carousel-item>
  </v-carousel>

  <!-- Fallback image if no roomAdditionalImages -->
  <v-img
    v-else
    width="1251px"
    :height="
      $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
        ? '600px'
        : $vuetify.breakpoint.name == 'md'
        ? '600px'
        : $vuetify.breakpoint.name == 'lg'
        ? '300px'
        : '300px'
    "
    src="./../../../assets/images/noimg.png"
  >
    <v-layout justify-start fill-height>
      <v-flex xs8 align-self-end mb-4>
        <span
          style="
            font-family: LexendFont;
            font-weight: 500;
            font-size: 22px;
            color: white;
          "
          v-if="roomData.roomsDisplayName"
          >{{ roomData.roomsDisplayName }}</span
        >
      </v-flex>
      <v-flex xs2 align-self-end text-right mb-4 pr-8>
        <v-btn
          style="border-radius: 2px"
          class="hidden-sm-and-down"
          color="grey"
          @click="$router.push('/editRoom?id=' + $route.query.id)"
        >
          <span
            style="
              font-family: LexendFont;
              font-weight: 600;
              font-size: 15px;
              color: white;
            "
            >Edit</span
          >
        </v-btn>
        <v-btn
          class="hidden-md-and-up"
          style="border-radius: 2px"
          text
          @click="$router.push('/editRoom?id=' + $route.query.id)"
        >
          <span
            style="
              font-family: LexendFont;
              font-weight: 600;
              font-size: 15px;
              color: white;
            "
            ><v-icon>mdi-pencil</v-icon></span
          >
        </v-btn>
      </v-flex>
    </v-layout>
  </v-img>
</v-flex>

          </v-layout>
          <v-layout wrap>
            <v-flex xs12>
              <v-layout wrap mt-2 v-if="roomData">
                <v-flex xs12 py-2>
                  <v-layout wrap>
                    <v-flex
                      xs12
                      pb-1
                      align-self-center
                      text-left
                      px-2
                      v-if="roomData"
                    >
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Description : </span
                      ><br />
                      <span
                        v-if="roomData.description"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ roomData.description }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex
                      xs12
                      sm2
                      pb-1
                      align-self-center
                      text-left
                      px-2
                      v-if="roomData.roomCategory"
                    >
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Category : </span
                      ><br />
                      <span
                        v-if="roomData.roomCategory"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ roomData.roomCategory.category_name }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex xs12 sm2 pb-1 align-self-center text-left px-2>
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Room View : </span
                      ><br />
                      <span
                        v-if="roomData.roomView"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ roomData.roomView }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex xs12 sm2 pb-1 align-self-center text-left px-2>
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Bed View :</span
                      ><br />
                      <span
                        v-if="roomData.bedView"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ roomData.bedView }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex xs12 sm2 pb-1 align-self-center text-left px-2>
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >View Type :</span
                      ><br />
                      <span
                        v-if="roomData.viewType"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ roomData.viewType }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex
                      xs12
                      sm2
                      pb-1
                      align-self-center
                      text-left
                      px-2
                      v-if="roomData.roomSize"
                    >
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Rooms Size :</span
                      ><br />
                      <span
                        v-if="roomData.roomSize.roomLength"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ roomData.roomSize.roomLength }} X
                      </span>
                      <span
                        v-if="roomData.roomSize.roomWidth"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ roomData.roomSize.roomWidth }}</span
                      >
                      <span
                        v-if="roomData.roomSize.area"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ roomData.roomSize.area }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>
                    <v-flex xs12 sm2 pb-1 align-self-center text-left px-2>
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Total Rooms :</span
                      ><br />
                      <span
                        v-if="roomData.totalRooms"
                        class="pt-2"
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                      >
                        {{ roomData.totalRooms }}</span
                      >
                      <span v-else>-</span>
                    </v-flex>

                    <v-flex
                      xs12
                      sm3
                      pt-2
                      align-self-start
                      text-left
                      px-2
                      pb-1
                      v-if="roomData"
                    >
                      <v-layout wrap justify-start>
                        <v-flex xs12 align-self-start>
                          <span
                            style="
                              font-family: LexendFont;
                              font-weight: 500;
                              font-size: 18px;
                            "
                            >Base Adults :</span
                          >
                          <span
                            v-if="roomData.minAdults"
                            style="
                              font-family: LexendFont;
                              font-weight: 300;
                              font-size: 16px;
                              color: black;
                            "
                            >{{ roomData.minAdults }}
                          </span>
                          <span v-else>-</span>
                        </v-flex>
                        <v-flex xs12 align-self-start>
                          <span
                            style="
                              font-family: LexendFont;
                              font-weight: 500;
                              font-size: 18px;
                            "
                            >Maximum Adults :</span
                          >
                          <span
                            v-if="roomData.maxAdults"
                            style="
                              font-family: LexendFont;
                              font-weight: 300;
                              font-size: 16px;
                              color: black;
                            "
                            >{{ roomData.maxAdults }}
                          </span>
                          <span v-else>-</span>
                        </v-flex>
                        <v-flex xs12 align-self-start>
                          <span
                            style="
                              font-family: LexendFont;
                              font-weight: 500;
                              font-size: 18px;
                            "
                            >Max Children :</span
                          >
                          <span
                            v-if="roomData.maxChilds"
                            style="
                              font-family: LexendFont;
                              font-weight: 300;
                              font-size: 16px;
                              color: black;
                            "
                            >{{ roomData.maxChilds }}
                          </span>
                          <span v-else>-</span>
                        </v-flex>
                        <v-flex xs12 align-self-start>
                          <span
                            style="
                              font-family: LexendFont;
                              font-weight: 500;
                              font-size: 18px;
                            "
                            >Max Occupancy :</span
                          >
                          <span
                            v-if="roomData.totalOccupency"
                            style="
                              font-family: LexendFont;
                              font-weight: 300;
                              font-size: 16px;
                              color: black;
                            "
                            >{{ roomData.totalOccupency }}
                          </span>
                          <span v-else>-</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      xs12
                      sm3
                      pt-2
                      align-self-start
                      text-left
                      px-2
                      pb-1
                      v-if="roomData"
                    >
                      <v-layout wrap justify-start>
                        <v-flex xs12 align-self-start>
                          <span
                            style="
                              font-family: LexendFont;
                              font-weight: 500;
                              font-size: 18px;
                            "
                            >Child Age :</span
                          >
                          <span
                            v-if="roomData.childAge"
                            style="
                              font-family: LexendFont;
                              font-weight: 300;
                              font-size: 16px;
                              color: black;
                            "
                            >{{ roomData.childAge }}
                          </span>
                          <span v-else>-</span>
                        </v-flex>
                        <v-flex xs12 align-self-start>
                          <span
                            style="
                              font-family: LexendFont;
                              font-weight: 500;
                              font-size: 18px;
                            "
                            >Infant Age :</span
                          >
                          <span
                            v-if="roomData.infantAge"
                            style="
                              font-family: LexendFont;
                              font-weight: 300;
                              font-size: 16px;
                              color: black;
                            "
                            >{{ roomData.infantAge }}
                          </span>
                          <span v-else>-</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      xs12
                      sm5
                      align-self-start
                      pt-2
                      text-left
                      px-2
                      pb-1
                      v-if="allRooms"
                    >
                      <span
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >Rooms List :</span
                      >
                      <v-layout wrap v-if="allRooms.length > 0">
                        <v-flex xs12 v-for="(item2, i) in allRooms" :key="i">
                          <span
                            style="
                              font-family: LexendFont;
                              font-weight: 300;
                              font-size: 16px;
                              color: black;
                            "
                          >
                            Room.No: {{ item2.roomNumber }} , Floor :
                            {{ item2.floorNumber }}</span
                          ><br />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm10 text-left><v-divider></v-divider></v-flex>
            <v-flex xs12 v-if="facilities">
              <div v-if="facilities.length > 0">
                <div
                  v-for="(facilities, category) in groupedFacilities"
                  :key="category"
                >
                  <v-layout wrap justify-start px-2 pt-4>
                    <v-flex xs12 text-left>
                      <span
                        class="facility-category"
                        style="
                          font-family: LexendFont;
                          font-weight: 500;
                          font-size: 18px;
                        "
                        >{{ category }} :</span
                      >
                    </v-flex>
                    <!-- Iterate over each facility in the current category -->
                    <v-flex
                      xs12
                      sm4
                      v-for="facility in facilities"
                      :key="facility._id"
                      pl-0
                      pl-md-4
                    >
                      <span
                        style="
                          color: black;
                          font-family: LexendFont;
                          font-weight: 300;
                          font-size: 16px;
                        "
                        >{{ facility.facilityId.name }}</span
                      >
                    </v-flex>
                  </v-layout>
                </div>
              </div>
            </v-flex>
            <v-flex xs12 sm10 text-left py-2><v-divider></v-divider></v-flex>
            <v-flex xs12 v-if="roomData">
              <v-layout wrap justify-start>
                <v-flex
                  xs12
                  sm2
                  pb-1
                  align-self-center
                  text-left
                  px-2
                  v-if="roomData.gstForFoodAndBeverages"
                >
                  <span
                    style="
                      font-family: LexendFont;
                      font-weight: 500;
                      font-size: 18px;
                    "
                    >GST : </span
                  ><br />
                  <span
                    v-if="roomData.gstForFoodAndBeverages === true"
                    class="pt-2"
                    style="
                      color: black;
                      font-family: LexendFont;
                      font-weight: 300;
                      font-size: 16px;
                    "
                    >GST Present</span
                  >
                  <span v-else>No GST</span>
                </v-flex>
                <v-flex
                  xs12
                  sm2
                  pb-1
                  align-self-center
                  text-left
                  px-2
                  v-if="roomData.foodAndBeveragesGstNo"
                >
                  <span
                    style="
                      font-family: LexendFont;
                      font-weight: 500;
                      font-size: 18px;
                    "
                    >GST Number : </span
                  ><br />
                  <span
                    v-if="roomData.foodAndBeveragesGstNo"
                    class="pt-2"
                    style="
                      color: black;
                      font-family: LexendFont;
                      font-weight: 300;
                      font-size: 16px;
                    "
                    >{{ roomData.foodAndBeveragesGstNo }}</span
                  >
                  <span v-else>-</span>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-start px-2>
                <v-flex xs12 pt-2>
                  <span
                    style="
                      font-family: LexendFont;
                      font-weight: 500;
                      font-size: 18px;
                    "
                    >Food & Beverages :
                  </span></v-flex
                >
                <v-flex
                  xs12
                  sm2
                  pb-1
                  align-self-center
                  text-left
                  px-2
                  v-if="roomData.isCake"
                >
                  <span
                    style="
                      color: black;
                      font-family: LexendFont;
                      font-weight: 300;
                      font-size: 16px;
                    "
                    >Cake :
                  </span>
                  <span
                    v-if="roomData.cakeRate"
                    class="pt-2"
                    style="
                      color: black;
                      font-family: LexendFont;
                      font-weight: 300;
                      font-size: 16px;
                    "
                  >
                    ₹ {{ roomData.cakeRate }}</span
                  >
                  <span v-else>-</span>
                </v-flex>
                <v-flex
                  xs12
                  sm3
                  pb-1
                  align-self-center
                  text-left
                  px-2
                  v-if="roomData.isFruitBasketIncluded"
                >
                  <span
                    style="
                      color: black;
                      font-family: LexendFont;
                      font-weight: 300;
                      font-size: 16px;
                    "
                    >Fruit Basket :
                  </span>
                  <span
                    v-if="roomData.fruitBasketRate"
                    class="pt-2"
                    style="
                      color: black;
                      font-family: LexendFont;
                      font-weight: 300;
                      font-size: 16px;
                    "
                  >
                    ₹ {{ roomData.fruitBasketRate }}</span
                  >
                  <span v-else>-</span>
                </v-flex>
                <v-flex
                  xs12
                  sm2
                  pb-1
                  align-self-center
                  text-left
                  px-2
                  v-if="roomData.isBBQGrillIncluded"
                >
                  <span
                    style="
                      color: black;
                      font-family: LexendFont;
                      font-weight: 300;
                      font-size: 16px;
                    "
                    >BBQ :
                  </span>
                  <span
                    v-if="roomData.bbqGrillRate"
                    class="pt-2"
                    style="
                      color: black;
                      font-family: LexendFont;
                      font-weight: 300;
                      font-size: 16px;
                    "
                  >
                    ₹ {{ roomData.bbqGrillRate }}</span
                  >
                  <span v-else>-</span>
                </v-flex>
                <v-flex
                  xs12
                  sm3
                  pb-1
                  align-self-center
                  text-left
                  px-2
                  v-if="roomData.cookAndButlerServiceIncluded"
                >
                  <span
                    style="
                      color: black;
                      font-family: LexendFont;
                      font-weight: 300;
                      font-size: 16px;
                    "
                    >Cook & Butler Service :
                  </span>
                  <span
                    v-if="roomData.cookAndButlerServiceRate"
                    class="pt-2"
                    style="
                      color: black;
                      font-family: LexendFont;
                      font-weight: 300;
                      font-size: 16px;
                    "
                  >
                    ₹ {{ roomData.cookAndButlerServiceRate }}</span
                  >
                  <span v-else>-</span>
                </v-flex>
                <v-flex
                  xs12
                  sm2
                  pb-1
                  align-self-center
                  text-left
                  px-2
                  v-if="roomData.honeyMoonInclusion"
                >
                  <span
                    style="
                      color: black;
                      font-family: LexendFont;
                      font-weight: 300;
                      font-size: 16px;
                    "
                    >Honeymoon :
                  </span>
                  <span
                    v-if="roomData.honeyMoonRate"
                    class="pt-2"
                    style="
                      color: black;
                      font-family: LexendFont;
                      font-weight: 300;
                      font-size: 16px;
                    "
                  >
                    ₹ {{ roomData.honeyMoonRate }}</span
                  >
                  <span v-else>-</span>
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  md3
                  pa-1
                  align-self-center
                  text-left
                  v-if="roomData.breakfastRate"
                >
                  <v-card class="pa-2">
                    <v-layout wrap justify-center>
                      <v-flex xs12 align-self-center>
                        <span
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                          >Breakfast :
                        </span>
                        <span
                          v-if="roomData.breakfastRate"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          ₹ {{ roomData.breakfastRate }}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex xs12 align-self-center>
                        <span
                          v-if="roomData.breakfastMenu"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          {{ roomData.breakfastMenu }}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  md3
                  pa-1
                  align-self-center
                  text-left
                  v-if="roomData.lunchRate"
                >
                  <v-card class="pa-2">
                    <v-layout wrap justify-center>
                      <v-flex xs12 align-self-center>
                        <span
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                          >Lunch :
                        </span>
                        <span
                          v-if="roomData.lunchRate"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          ₹ {{ roomData.lunchRate }}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex xs12 align-self-center>
                        <span
                          v-if="roomData.lunchMenu"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          {{ roomData.lunchMenu }}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  md3
                  pa-1
                  align-self-center
                  text-left
                  v-if="roomData.snacksRate"
                >
                  <v-card class="pa-2">
                    <v-layout wrap justify-center>
                      <v-flex xs12 align-self-center>
                        <span
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                          >Snacks :
                        </span>
                        <span
                          v-if="roomData.snacksRate"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          ₹ {{ roomData.snacksRate }}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex xs12 align-self-center>
                        <span
                          v-if="roomData.snacksMenu"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          {{ roomData.snacksMenu }}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  md3
                  pa-1
                  align-self-center
                  text-left
                  v-if="roomData.dinnerRate"
                >
                  <v-card class="pa-2">
                    <v-layout wrap justify-center>
                      <v-flex xs12 align-self-center>
                        <span
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                          >Dinner :
                        </span>
                        <span
                          v-if="roomData.dinnerRate"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          ₹ {{ roomData.dinnerRate }}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex xs12 align-self-center>
                        <span
                          v-if="roomData.dinnerMenu"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          {{ roomData.dinnerMenu }}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm10 text-left py-2><v-divider></v-divider></v-flex>
          </v-layout>
          <v-layout wrap justify-start px-2>
            <v-flex xs4 sm1 align-self-center>
              <span
                style="
                  font-family: LexendFont;
                  font-weight: 500;
                  font-size: 18px;
                "
                >Package :
              </span>
            </v-flex>
            <v-flex xs4 sm2 px-1 align-self-center>
              <v-btn
                @click="selectPackageType('B2B')"
                :color="packageType === 'B2B' ? '#ff6200' : '#cccccc'"
                block
                dark
              >
                B2B
              </v-btn>
            </v-flex>
            <v-flex xs4 sm2 px-1 align-self-center>
              <v-btn
                @click="selectPackageType('B2C')"
                :color="packageType === 'B2C' ? '#ff6200' : '#cccccc'"
                block
                dark
              >
                B2C
              </v-btn> </v-flex
            ><v-spacer></v-spacer>
            <v-flex xs2 sm2 px-1 align-self-center>
              <v-btn
                @click="CreateRateDialog = true"
                color="#ff6200"
                text
                block
                dark
              >
                Create New <v-icon color="#ff6200" small>mdi-plus</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-space-around pt-4 v-if="roomRateData">
            <v-flex xs12 px-0 px-sm-2 v-if="roomRateData.length > 0">
              <v-card elevation="0" pa-1 outlined>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left tablefont"><b>S.No.</b></th>
                        <th class="text-left tablefont"><b>Start Date</b></th>
                        <th class="text-left tablefont"><b>End Date</b></th>
                        <th class="text-left tablefont"><b>Base Rates</b></th>
                        <th class="text-left tablefont">
                          <b>Paid Child Rates (W/ Mattress)</b>
                        </th>
                        <th class="text-left tablefont">
                          <b>Paid Child Rates (W/O Mattress)</b>
                        </th>
                        <!-- <th class="text-left tablefont"><b>Free Child Rate</b></th> -->
                        <th class="text-left tablefont">
                          <b>Extra Adult Rates (W/ Mattress)</b>
                        </th>
                        <th class="text-left tablefont">
                          <b>Extra Adult Rates (W/O Mattress)</b>
                        </th>
                        <th class="text-left tablefont"><b>Booking Type</b></th>
                        <th class="text-left tablefont"><b>Rate Type</b></th>
                        <th class="text-left tablefont">
                          <b>GST Calculations</b>
                        </th>
                        <th class="text-left tablefont"><b>Min Rooms</b></th>
                        <th class="text-left tablefont">
                          <b>Breakfast Included</b>
                        </th>
                        <th class="text-left tablefont">
                          <b>Welcome Drink</b>
                        </th>
                        <th class="text-left tablefont">
                          <b>Additional Rules</b>
                        </th>
                        <th class="text-left tablefont"><b>Edit</b></th>
                        <th class="text-left tablefont"><b>Delete</b></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, i) in roomRateData"
                        :key="i"
                        class="table"
                        height="70px"
                      >
                        <td>
                          <span v-if="page == 1">{{ i + 1 }}</span>
                          <span v-else>{{ i + 1 + 20 * (page - 1) }}</span>
                        </td>
                        <td>
                          <span> {{ formatDate(value.startDate) }}</span>
                        </td>
                        <td>
                          <span> {{ formatDate(value.endDate) }}</span>
                        </td>
                        <td>
                          <span>{{ value.basePrice }}</span>
                        </td>
                        <td>
                          <span>{{ value.paidChildRatewithExtraMatress }}</span>
                        </td>
                        <td>
                          <span>{{
                            value.paidChildRatewithoutExtraMatress
                          }}</span>
                        </td>
                        <!-- <td>
            <span>{{ value.freeChildRate }}</span>
          </td> -->
                        <td>
                          <span>{{
                            value.extraAdultRateWithExtraMatress
                          }}</span>
                        </td>
                        <td>
                          <span>{{
                            value.extraAdultRateWithoutExtraMatress
                          }}</span>
                        </td>
                        <td>
                          <span>{{ value.packageType }}</span>
                        </td>
                        <td>
                          <span>{{ value.rateType }}</span>
                        </td>
                        <td>
                          <span>{{ value.gstCalculations }}</span>
                        </td>
                        <td>
                          <span>{{ value.minRooms }}</span>
                        </td>
                        <td>
                          <span>{{
                            value.breakfastIncluded ? "Yes" : "No"
                          }}</span>
                        </td>
                        <td>
                          <span>{{
                            value.haveWelcomeDrink ? "Yes" : "No"
                          }}</span>
                        </td>
                        <td>
                          <span>{{ value.additionalRules }}</span>
                        </td>
                        <td>
                          <v-icon
                            @click="(EditRateDialog = true), (editData = value)"
                            >mdi-pencil</v-icon
                          >
                        </td>
                        <td>
                          <v-icon
                            @click="
                              (deleteialog = true)((deleteId = value._id))
                            "
                            >mdi-trash-can-outline</v-icon
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-space-around v-else>
            <v-flex xs12 text-center> No Data Available </v-flex>
          </v-layout>
        </v-card>
        <v-dialog width="400px" v-model="deleteialog">
          <v-card width="400px" class="pa-2">
            <v-layout wrap justify-center>
              <v-flex xs11 text-center
                ><span style="color: #002635" class="dialogHead"
                  >Delete</span
                ></v-flex
              >
              <v-flex xs1 text-right>
                <v-icon @click="deleteialog = false" color="#002635"
                  >mdi-close-box</v-icon
                ></v-flex
              >
              <v-flex xs8 text-center py-4>
                <v-divider></v-divider>
              </v-flex>
            </v-layout>
            <v-card-text class="px-4 pb-0 dialogText text-center"
              >Are you sure you want to delete this rate?
            </v-card-text>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn color="#002635" class="dialogText" dark @click="delete1()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog width="800px" v-model="CreateRateDialog">
          <v-card width="800px" class="pa-4">
            <v-layout wrap justify-center pb-4 px-8>
              <v-flex xs12 pt-4 text-left>
                <span
                  style="
                    font-family: LexendFont;
                    font-weight: 500;
                    font-size: 18px;
                  "
                  >Add New Rate</span
                >
              </v-flex>
              <v-flex xs12 pt-4 text-left><v-divider></v-divider></v-flex>
              <v-flex xs12 sm6>
                <v-layout wrap justify-center>
                  <v-flex xs12 sm10 pt-4 text-left>
                    <span class="title2">Start Date</span>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="startDate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDate"
                          outlined
                          readonly
                          dense
                          hide-details="auto"
                          clearable
                          class="rounded-0"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        no-title
                        :min="startDate"
                        scrollable
                        @change="$refs.menu.save(startDate)"
                      >
                        <v-spacer></v-spacer>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm10 pt-4 text-left>
                    <span class="title2">End Date</span>
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :return-value.sync="endDate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endDate"
                          outlined
                          readonly
                          dense
                          hide-details="auto"
                          clearable
                          class="rounded-0"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        no-title
                        :min="endDate"
                        scrollable
                        @change="$refs.menu2.save(endDate)"
                      >
                        <v-spacer></v-spacer>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm10 pt-4 text-left>
                    <span class="title2"
                      >Base Rates (Single/Double Occupency)</span
                    >
                    <v-text-field
                      dense
                      v-model="baseRate"
                      outlined
                      type="number"
                      hide-spin-buttons
                      hide-details="auto"
                    >
                    </v-text-field>
                    <span v-if="netRate" class="title3" style="color: red"
                      >Net Rate: ₹ {{ netRate }}</span
                    >
                  </v-flex>
                  <v-flex xs12 sm10 pt-4 text-left>
                    <span class="title2"
                      >Paid Child Rates({{ roomData.childAge }})/Extra
                      Matters</span
                    >
                    <v-text-field
                      dense
                      v-model="paidChildWithmatter"
                      outlined
                      type="number"
                      hide-spin-buttons
                      hide-details="auto"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm10 pt-4 text-left>
                    <span class="title2"
                      >Paid Child Rates({{ roomData.childAge }})/Without
                      Matters</span
                    >
                    <v-text-field
                      dense
                      v-model="paidChildWithoutmatter"
                      outlined
                      type="number"
                      hide-spin-buttons
                      hide-details="auto"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm10 pt-4 text-left>
                    <span class="title2">Extra Adult Rates /Extra Matters</span>
                    <v-text-field
                      dense
                      v-model="extraAdultWithmatter"
                      outlined
                      type="number"
                      hide-spin-buttons
                      hide-details="auto"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm10 pt-4 text-left>
                    <span class="title2"
                      >Extra Adult Rates /Without Matters</span
                    >
                    <v-text-field
                      dense
                      v-model="extraAdultWithoutmatter"
                      outlined
                      type="number"
                      hide-spin-buttons
                      hide-details="auto"
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6>
                <v-layout wrap justify-center fill-height>
                  <v-flex xs12 sm10 align-self-start pt-4 text-left>
                    <span class="title2">Booking Type </span>

                    <v-radio-group
                      dense
                      v-model="bookingType"
                      row
                      class="mt-0 pt-0"
                      hide-details="auto"
                    >
                      <v-radio
                        color="orange"
                        label="Group Booking"
                        value="Group Package"
                      ></v-radio>
                      <v-radio
                        color="orange"
                        label="Single Booking"
                        value="Single Room"
                      ></v-radio>
                    </v-radio-group>
                  </v-flex>
                  <v-flex xs12 sm10 align-self-start pt-4 text-left>
                    <span class="title2">Rate Type </span>

                    <v-radio-group
                      dense
                      v-model="rateType"
                      row
                      class="mt-0 pt-0"
                      hide-details="auto"
                    >
                      <v-radio color="orange" label="B2B" value="B2B"></v-radio>
                      <v-radio color="orange" label="B2C" value="B2C"></v-radio>
                    </v-radio-group>
                  </v-flex>

                  <v-flex xs12 sm10 align-self-start pt-4 text-left>
                    <span class="title2">GST</span>
                    <v-radio-group
                      dense
                      v-model="GSTType"
                      row
                      class="mt-0 pt-0"
                      hide-details="auto"
                    >
                      <v-radio
                        color="orange"
                        label="Including Gst"
                        value="Inclusive"
                      ></v-radio>
                      <v-radio
                        color="orange"
                        label="Excluding GST"
                        value="Exclusive"
                      ></v-radio>
                    </v-radio-group>
                  </v-flex>
                  <v-flex xs12 sm10 align-self-start pt-4 pt-md-4 text-left>
                    <span class="title2">Food </span>

                    <v-radio-group
                      dense
                      v-model="isFood"
                      row
                      class="mt-0 pt-0"
                      hide-details="auto"
                    >
                      <v-radio
                        color="orange"
                        label="Include Breakfast"
                        :value="true"
                      ></v-radio>
                      <v-radio
                        color="orange"
                        label="Room Only"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </v-flex>
                  <v-flex xs12 sm10 align-self-start text-left>
                    <v-layout wrap justify-start>
                      <v-flex
                        xs12
                        sm6
                        align-self-center
                        pr-0
                        pr-md-1
                        pt-4
                        text-left
                      >
                        <v-switch
                          v-model="isDrink"
                          label="Welcome Drink"
                          hide-details
                          color="orange"
                          class="mt-0"
                        ></v-switch>
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        align-self-center
                        pl-0
                        pl-md-1
                        pt-4
                        text-left
                        v-if="isDrink == true"
                      >
                        <v-radio-group
                          dense
                          v-model="drinkType"
                          row
                          class="mt-0 pt-0"
                          hide-details="auto"
                        >
                          <v-radio
                            color="orange"
                            label="Free"
                            value="false"
                          ></v-radio>
                          <v-radio
                            color="orange"
                            label="Paid"
                            value="true"
                          ></v-radio>
                        </v-radio-group>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs12
                    sm10
                    align-self-start
                    pt-4
                    text-left
                    v-if="bookingType == 'Group Package'"
                  >
                    <span class="title2">Min.Rooms</span>

                    <v-text-field
                      dense
                      v-model="minRoom"
                      outlined
                      type="number"
                      hide-spin-buttons
                      hide-details="auto"
                    >
                    </v-text-field>
                  </v-flex>
                  <!-- <v-flex xs12 sm5 pr-0 pr-md-1 pt-4 text-left v-if="bookingType=='single'">
            <span class="title2">Max.Capacity</span>
            <v-text-field
              dense
              v-model="maxCapacity"
              outlined
              type="number"  hide-spin-buttons
              hide-details="auto"
            >
            </v-text-field>
</v-flex>
<v-flex xs12 sm5 pl-0 pl-md-1 pt-4 text-left v-if="bookingType=='single'">
            <span class="title2">Extra.Person</span>
            <v-text-field
              dense
              v-model="extraPerson"
              outlined
              type="number"  hide-spin-buttons
              hide-details="auto"
            >
            </v-text-field>
</v-flex> -->
                  <!-- <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Infant / Complimentary Free</span>
            <v-text-field
              dense
              v-model="roomData.infantAge"
              outlined disabled
              hide-details="auto"
            >
            </v-text-field>
</v-flex> -->
                  <v-flex xs12 sm10 align-self-start pt-4 text-left>
                    <span class="title2">Additional Rules</span>
                    <v-textarea
                      dense
                      v-model="additionalRules"
                      outlined
                      auto-grow
                      rows="4"
                      type="text"
                      hide-details="auto"
                    >
                    </v-textarea>
                  </v-flex>
                  <v-flex xs12 sm10 pt-4 text-left align-self-baseline>
                    <v-layout wrap justify-end>
                      <v-flex xs12 sm6>
                        <v-btn
                          @click="AddRoomRate()"
                          block
                          class="btnstly"
                          style="cursor: pointer"
                          ><span
                            style="
                              font-family: LexendFont;
                              text-transform: none;
                            "
                            >Save</span
                          ></v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>
        <v-dialog width="800px" v-model="EditRateDialog">
          <v-card width="800px" class="pa-4">
            <v-layout wrap justify-center pb-4 px-8>
              <v-flex xs12 pt-4 text-left>
                <span
                  style="
                    font-family: LexendFont;
                    font-weight: 500;
                    font-size: 18px;
                  "
                  >Edit Rate</span
                >
              </v-flex>
              <v-flex xs12 pt-4 text-left><v-divider></v-divider></v-flex>
              <v-flex xs12 sm6>
                <v-layout wrap justify-center>
                  <v-flex xs12 sm10 pt-4 text-left>
                    <span class="title2">Start Date</span>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="startDate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editData.startDate"
                          outlined
                          readonly
                          dense
                          hide-details="auto"
                          clearable
                          class="rounded-0"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editData.startDate"
                        no-title
                        :min="startDate"
                        scrollable
                        @change="$refs.menu.save(startDate)"
                      >
                        <v-spacer></v-spacer>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm10 pt-4 text-left>
                    <span class="title2">End Date</span>
                    <v-menu
                      ref="menu2"
                      v-model="editData.menu2"
                      :close-on-content-click="false"
                      :return-value.sync="endDate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editData.endDate"
                          outlined
                          readonly
                          dense
                          hide-details="auto"
                          clearable
                          class="rounded-0"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editData.endDate"
                        no-title
                        :min="endDate"
                        scrollable
                        @change="$refs.menu2.save(endDate)"
                      >
                        <v-spacer></v-spacer>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm10 pt-4 text-left>
                    <span class="title2"
                      >Base Rates (Single/Double Occupency)</span
                    >
                    <v-text-field
                      dense
                      v-model="editData.basePrice"
                      outlined
                      type="number"
                      hide-spin-buttons
                      hide-details="auto"
                    >
                    </v-text-field>
                    <span v-if="netRate" class="title3" style="color: red"
                      >Net Rate: ₹ {{ netRate }}</span
                    >
                  </v-flex>
                  <v-flex xs12 sm10 pt-4 text-left>
                    <span class="title2"
                      >Paid Child Rates({{ editData.childAge }})/Extra
                      Matters</span
                    >
                    <v-text-field
                      dense
                      v-model="editData.paidChildRatewithExtraMatress"
                      outlined
                      type="number"
                      hide-spin-buttons
                      hide-details="auto"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm10 pt-4 text-left>
                    <span class="title2"
                      >Paid Child Rates({{ editData.childAge }})/Without
                      Matters</span
                    >
                    <v-text-field
                      dense
                      v-model="editData.paidChildRatewithoutExtraMatress"
                      outlined
                      type="number"
                      hide-spin-buttons
                      hide-details="auto"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm10 pt-4 text-left>
                    <span class="title2">Extra Adult Rates /Extra Matters</span>
                    <v-text-field
                      dense
                      v-model="editData.extraAdultRateWithExtraMatress"
                      outlined
                      type="number"
                      hide-spin-buttons
                      hide-details="auto"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm10 pt-4 text-left>
                    <span class="title2"
                      >Extra Adult Rates /Without Matters</span
                    >
                    <v-text-field
                      dense
                      v-model="editData.extraAdultRateWithoutExtraMatress"
                      outlined
                      type="number"
                      hide-spin-buttons
                      hide-details="auto"
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6>
                <v-layout wrap justify-center fill-height>
                  <v-flex xs12 sm10 align-self-start pt-4 text-left>
                    <span class="title2">Booking Type </span>

                    <v-radio-group
                      dense
                      v-model="editData.rateType"
                      row
                      class="mt-0 pt-0"
                      hide-details="auto"
                    >
                      <v-radio
                        color="orange"
                        label="Group Booking"
                        value="Group Package"
                      ></v-radio>
                      <v-radio
                        color="orange"
                        label="Single Booking"
                        value="Single Room"
                      ></v-radio>
                    </v-radio-group>
                  </v-flex>
                  <v-flex xs12 sm10 align-self-start pt-4 text-left>
                    <span class="title2">Rate Type </span>

                    <v-radio-group
                      dense disabled
                      v-model="editData.packageType"
                      row
                      class="mt-0 pt-0"
                      hide-details="auto"
                    >
                      <v-radio color="orange" label="B2B" value="B2B"></v-radio>
                      <v-radio color="orange" label="B2C" value="B2C"></v-radio>
                    </v-radio-group>
                  </v-flex>

                  <v-flex xs12 sm10 align-self-start pt-4 text-left>
                    <span class="title2">GST</span>
                    <v-radio-group
                      dense
                      v-model="editData.gstCalculations"
                      row
                      class="mt-0 pt-0"
                      hide-details="auto"
                    >
                      <v-radio
                        color="orange"
                        label="Including Gst"
                        value="Inclusive"
                      ></v-radio>
                      <v-radio
                        color="orange"
                        label="Excluding GST"
                        value="Exclusive"
                      ></v-radio>
                    </v-radio-group>
                  </v-flex>
                  <v-flex xs12 sm10 align-self-start pt-4 pt-md-4 text-left>
                    <span class="title2">Food </span>

                    <v-radio-group
                      dense
                      v-model="editData.breakfastIncluded"
                      row
                      class="mt-0 pt-0"
                      hide-details="auto"
                    >
                      <v-radio
                        color="orange"
                        label="Include Breakfast"
                        :value="true"
                      ></v-radio>
                      <v-radio
                        color="orange"
                        label="Room Only"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </v-flex>
                  <v-flex xs12 sm10 align-self-start text-left>
                    <v-layout wrap justify-start>
                      <v-flex
                        xs12
                        sm6
                        align-self-center
                        pr-0
                        pr-md-1
                        pt-4
                        text-left
                      >
                        <v-switch
                          v-model="editData.haveWelcomeDrink"
                          label="Welcome Drink"
                          hide-details
                          color="orange"
                          class="mt-0"
                        ></v-switch>
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        align-self-center
                        pl-0
                        pl-md-1
                        pt-4
                        text-left
                        v-if="isDrink == true"
                      >
                        <v-radio-group
                          dense
                          v-model="editData.drinkType"
                          row
                          class="mt-0 pt-0"
                          hide-details="auto"
                        >
                          <v-radio
                            color="orange"
                            label="Free"
                            value="false"
                          ></v-radio>
                          <v-radio
                            color="orange"
                            label="Paid"
                            value="true"
                          ></v-radio>
                        </v-radio-group>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs12
                    sm10
                    align-self-start
                    pt-4
                    text-left
                    v-if="bookingType == 'Group Package'"
                  >
                    <span class="title2">Min.Rooms</span>

                    <v-text-field
                      dense
                      v-model="editData.minRooms"
                      outlined
                      type="number"
                      hide-spin-buttons
                      hide-details="auto"
                    >
                    </v-text-field>
                  </v-flex>
                  <!-- <v-flex xs12 sm5 pr-0 pr-md-1 pt-4 text-left v-if="bookingType=='single'">
            <span class="title2">Max.Capacity</span>
            <v-text-field
              dense
              v-model="editData.maxCapacity"
              outlined
              type="number"  hide-spin-buttons
              hide-details="auto"
            >
            </v-text-field>
</v-flex>
<v-flex xs12 sm5 pl-0 pl-md-1 pt-4 text-left v-if="bookingType=='single'">
            <span class="title2">Extra.Person</span>
            <v-text-field
              dense
              v-model="editData.extraPerson"
              outlined
              type="number"  hide-spin-buttons
              hide-details="auto"
            >
            </v-text-field>
</v-flex> -->
                  <!-- <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Infant / Complimentary Free</span>
            <v-text-field
              dense
              v-model="editData.roomData.infantAge"
              outlined disabled
              hide-details="auto"
            >
            </v-text-field>
</v-flex> -->
                  <v-flex xs12 sm10 align-self-start pt-4 text-left>
                    <span class="title2">Additional Rules</span>
                    <v-textarea
                      dense
                      v-model="editData.additionalRules"
                      outlined
                      auto-grow
                      rows="4"
                      type="text"
                      hide-details="auto"
                    >
                    </v-textarea>
                  </v-flex>
                  <v-flex xs12 sm10 pt-4 text-left align-self-baseline>
                    <v-layout wrap justify-end>
                      <v-flex xs12 sm6>
                        <v-btn
                          @click="EditRoomRate()"
                          block
                          class="btnstly"
                          style="cursor: pointer"
                          ><span
                            style="
                              font-family: LexendFont;
                              text-transform: none;
                            "
                            >Save</span
                          ></v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
    <script>
import axios from "axios";
export default {
  data() {
    return {
      showSnackBar: false,
      timeout: 2000,
      ServerError: false,
      appLoading: false,
      roomData: {},
      packageData: [],
      allRooms: [],
      facilities: [],
      roomRateData: [],
      packageType: "B2B",
      msg: "",
      page: 1,
      limit: 20,
      deleteialog: false,
      deleteId: "",
      EditRateDialog: false,
      editData: {},
      //rate add
      startDate: new Date().toISOString().slice(0, 10),
      endDate: new Date().toISOString().slice(0, 10),
      nowDate: new Date().toISOString().slice(0, 10),
      menu: false,
      menu2: false,
      CreateRateDialog: false,
      baseRate: "",
      bookingType: "Single Room",
      rateType: "B2B",
      GSTType: "",
      isFood: "",
      isDrink: true,
      drinkType: true,
      minRoom: "",
      ageRange: "0-5",
      additionalRules: "",
      paidChildWithmatter: "",
      paidChildWithoutmatter: "",
      extraAdultWithmatter: "",
      extraAdultWithoutmatter: "",
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !value || pattern.test(value) || "Invalid e-mail.";
        },
      },
      pinRules: [
        (v) => !!v || "PIN is required",
        (v) => (v && v.length === 6) || "PIN must be 6 digits",
      ],
      phoneRules: [
        (v) => !v || v.length <= 10 || "number must be less than 11 characters",
        (v) =>
          !v || v.length >= 10 || "number must be greater than 10 characters",
        (v) => !v || /^\d{10}$/.test(v) || "phone number must be a number",
      ],
    };
  },
  watch: {
    page() {
      this.getData();
    },
  },

  mounted() {
    this.getData();
    this.getRate();
  },
  computed: {
    netRate() {
      if (this.baseRate) {
        return (this.baseRate * (100 / 110)).toFixed(2);
      }
      return null;
    },

    groupedFacilities() {
      // Group facilities by facilityId.facilityCategory
      return this.facilities.reduce((acc, facility) => {
        const category =
          facility.facilityId.facilityCategory || "Unknown Category";
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(facility);
        return acc;
      }, {});
    },
  },
  methods: {
    selectPackageType(type) {
      this.packageType = type; // Set the selected package type
      this.getRate(); // Call API to get the rate for the selected package type
    },
    getRate() {
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/hotel/package/rate/list",
        data: {
          roomId: this.$route.query.id,
          packageType: this.packageType,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.roomRateData = response.data.details;
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
    getData() {
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/room/details",
        data: {
          roomId: this.$route.query.id,
          page: this.page,
          limit: this.limit,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.roomData = response.data.data;
          this.allRooms = response.data.allRooms;
          this.facilities = response.data.facilities;
          this.packageData = response.data.package;
          console.log("packageData", this.packageData);
          this.Pagelength = Math.ceil(response.data.count / this.limit);
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      // +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
    delete1() {
      axios({
        method: "POST",
        url: "/hotel/package/rate/delete",
        data: {
          id: this.deleteId,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        if (response.data.status == true) {
          this.msg = response.data.msg;
          this.showSnackbar = true;
          this.deleteialog = false;
          this.getRate();
        } else {
          this.msg = response.data.msg;
          this.showSnackbar = true;
        }
      });
      // }
    },
    // rate add
    increment(field) {
      this.occupency[field]++;
    },
    decrement(field) {
      if (this.occupency[field] > 0) {
        this.occupency[field]--;
      }
    },
    addNewNo() {
      if (this.newRoomNumber && this.newFloor) {
        this.roomNumbers.push({
          roomNumber: this.newRoomNumber,
          floorNumber: this.newFloor,
        });
        this.newRoomNumber = "";
        this.newFloor = "";
      }
    },
    deleteRoomNo(index) {
      this.roomNumbers.splice(index, 1);
    },

    validation() {
      if (!this.category) {
        this.msg = "Please choose boat category";
        this.showSnackBar = true;
        return;
      } else if (!this.roomName) {
        this.msg = "Please enter property boat name";
        this.showSnackBar = true;
        return;
      } else if (!this.description) {
        this.msg = "Please enter total rooms";
        this.showSnackBar = true;
        return;
      } else if (!this.roomType) {
        this.msg = "Please choose type";
        this.showSnackBar = true;
        return;
      } else if (!this.roomView) {
        this.msg = "Please choose room view";
        this.showSnackBar = true;
        return;
      } else if (!this.otpMail) {
        this.msg = "Please enter OTP mail Id";
        this.showSnackBar = true;
        return;
      } else if (!this.expiryType) {
        this.msg = "Please choose expiry type";
        this.showSnackBar = true;
        return;
      } else if (!this.expiryDate) {
        this.msg = "Please enter expiry date";
        this.showSnackBar = true;
        return;
      } else if (!this.attachments) {
        this.msg = "Please upload boat licence proof";
        this.showSnackBar = true;
        return;
      } else if (!this.attachments2) {
        this.msg = "Please upload lease proof";
        this.showSnackBar = true;
        return;
      } else {
        this.addBoat();
      }
    },
    AddRoomRate() {
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/room/package/rate/add",
        data: {
          startDate: this.startDate,
          endDate: this.endDate,
          basePrice: this.baseRate,
          paidChildRatewithExtraMatress: this.paidChildWithmatter,
          paidChildRatewithoutExtraMatress: this.paidChildWithoutmatter,
          extraAdultRateWithExtraMatress: this.extraAdultWithmatter,
          extraAdultRateWithoutExtraMatress: this.extraAdultWithoutmatter,
          haveWelcomeDrink: this.isDrink,
          additionalRules: this.additionalRules,
          breakfastIncluded: this.isFood,
          rateType: this.bookingType,
          packageType: this.rateType,
          welcomeDrinkPaid: this.drinkType,
          minRooms: this.minRoom,
          gstCalculations: this.GSTType,
          roomId: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.EditRateDialog = false;
          this.packageType = this.rateType;
          this.getRate();
          //  localStorage.removeItem("RoomId");
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
    //rate edit
    EditRoomRate() {
      this.appLoading = true;
      console.log("EditRoomRate=", this.EditRoomRate);
      axios({
        method: "POST",
        url: "/hotel/package/rate/update",
        data: {
          startDate: this.editData.startDate,
          endDate: this.editData.endDate,
          basePrice: this.editData.basePrice,
          paidChildRatewithExtraMatress:
            this.editData.paidChildRatewithExtraMatress,
          paidChildRatewithoutExtraMatress:
            this.editData.paidChildRatewithoutExtraMatress,
          extraAdultRateWithExtraMatress:
            this.editData.extraAdultRateWithExtraMatress,
          extraAdultRateWithoutExtraMatress:
            this.editData.extraAdultRateWithoutExtraMatress,
          haveWelcomeDrink: this.editData.haveWelcomeDrink,
          additionalRules: this.editData.additionalRules,
          breakfastIncluded: this.editData.breakfastIncluded,
          rateType: this.editData.rateType,
          packageType: this.editData.packageType,
          welcomeDrinkPaid: this.editData.drinkType,
          minRooms: this.editData.minRooms,
          gstCalculations: this.editData.gstCalculations,
          freeChildRate: this.editData.freeChildRate,
          id: this.editData._id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.CreateRateDialog = false;
          this.packageType = this.rateType;
          this.getRate();
          //  localStorage.removeItem("RoomId");
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
  },
};
</script>
  <style scoped>
.btnstly {
  background: linear-gradient(
    to bottom,
    rgba(255, 133, 86, 1),
    rgba(247, 75, 9, 1),
    rgba(255, 70, 0, 1)
  );
  color: white;
}

@media (max-width: 600px) {
  .v-tabs {
    touch-action: none;
  }
}
</style>